import React from "react";
import { Row, Col, Container } from "reactstrap";
import image1 from "../assets/img/Picture1.jpg";
import image2 from "../assets/img/Picture2.jpg";
import image3 from "../assets/img/Picture3.jpg";
import image4 from "../assets/img/Picture4.jpg";
import image5 from "../assets/img/Picture5.jpg";
import image6 from "../assets/img/Picture6.jpg";
import image7 from "../assets/img/Picture7.jpg";
import image8 from "../assets/img/Picture8.png";

function OurGoals() {
  return (
    <>
      <Container className="featured_story">
        <Row className="first">
          <Col className="left text-center" md='8'>
            <p>Opinion By <a href=""><u>Henry DeWitt</u></a>, Sports Editor and High School Athlete <br /> <b><u>First Published October 31, 2019</u></b> - in LHSBudget <br /> Lawrence High School, Lawrence, KS 66046</p>
            <span>...........................................................................................................................................</span>
            <h3>“There are hungry nights that I go to bed, and I’m starving,” Shabazz Nabier, a former UConn guard, told reporters after winning the NCAA championship.</h3>
          </Col>
          <Col md='4' className="fs-img1" >
            <img src={image1} alt="img" />
          </Col>
        </Row>
        <Row>
          <Col md='12' className="text-center">
            <h3>
              ‘These athletes often have to go to sleep hungry because they don’t have any source of income. So what if a fan offers them a sandwich because they haven’t eaten in some cases days? NCAA violation.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md='7'>
            <h3>‘Athletes cannot accept gifts. What if a college athlete is getting married? They better not have a regular wedding with a guest registry because that would be an NCAA violation, as was the case with Kyle Guy, former Virginia Cavalier. </h3>
          </Col>
          <Col md='5' className="fs-img2" >
            <img src={image2} alt="img" />
          </Col>
        </Row>
        <Row>
          <Col md='3' className="fs-img2" >
            <img src={image3} alt="img" />
          </Col>
          <Col md='5'>
            <h3>‘If a college student sold a poster of the basketball team at a basketball game, the student selling the poster would be paid more money from the university than the players whose faces are plastered on the poster.
            </h3>
          </Col>
          <Col md='4' className="fs-img2" >
            <img src={image4} alt="img" />
          </Col>
        </Row>
        <Row>
          <Col md='7'>
            <h3>
              ‘However, when a college athlete gets a career-ending injury, at least they have their education. Except that’s not true.
            </h3>
          </Col>
          <Col md='5' className="fs-img2" >
            <img src={image5} alt="img" />
          </Col>
        </Row>
        <Row>
          <Col md='4' className="fs-img2" >
            <img src={image6} alt="img" />
          </Col>
          <Col md='8'>
            <h3>
              If an already low-income student athlete were to sustain a severe injury, they would have to pay for their own medical bills because the NCAA made it so schools don’t have to compensate for injuries. Also, the NCAA is within its rights to completely revoke scholarships at any time because the production on the field is not there. So now the already poor student athlete is having to pay for not only their medical bills but now their college tuition.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md='12'>
            <h3>
              <u>This cycle often leads to talented athletes dropping out of school</u> because they are not rich enough to pay for their school without the scholarships they were promised, as was the case with Kyle Hardrick who was stripped of his full ride scholarship after tearing his ACL his freshman year.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md='12'>
            <h3>
            <img className="fs-img7" src={image7} alt="img" />
            ‘It is clear that the NCAA cares more about the product on the field than the actual athletes themselves. They have shown that year after year, and they will continue to prove that until people stop believing the lies and deception that they preach.
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md='12'>
            <h3>
            ‘Next time you put money on your March Madness Bracket realize that you just bet more money than that star college athlete is allowed to make in all of their college career. It could have helped keep their parents’ lights on for the month. And maybe they might not be performing to your standards because they haven’t eaten in days. But, hey, at least they have an education.’
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md='5' className="fs-img2" >
            <img src={image8} alt="img" />
          </Col>
          <Col md='7'>
            <h3>
              NOTE: This article was written before the US Supreme Court’s 2021 Alston opinion which paved the way for Name-Image-Likeness payments to student athletes, i.e., this article was written before FHP, CA, & M4F.
            </h3>
          </Col>
        </Row>
      </Container>
      {/* <div>
    <Container>
     <Row className="py-5">
     <Col md="12" className="text-justify fs-text">
       <p>
        Lorem, ipsum <a href="/" >amet consectetur</a>, dolor sit adipisicing elit. Voluptas nobis. <br /> October 31, 2019 <br /> Published in LHS <br /> Lawranece High school <br />
        1901 Louisiana street lawrance, KS, 60666 <br /> <hr className="dotted_line" />
       </p>
      </Col>
      <Col md="12" className="text-justify fs-text">
       <p>
        “Diana is a hard-working, self-driven and creative student,” said
        Professor who had the senior in her advanced
        reporting class. “She revels in coming up with her own story ideas and
        writing in a lyrical way. She’s always eager to learn new approaches to
        journalism.”
       </p>
      </Col>
      <Col md="12" className="fs-img2">
       <img src={image4} alt="img" />
      </Col>
      <Col md="12" className="text-justify fs-text">
        <br />
       <p>
        Diana Ramos is the Venezuelan national indoor high jump record holder at
        5 feet, 7.25 inches. At 5-10, University of Arizona high jumper can clear
        a bar only 2 inches shorter than her height, using speed, strength,
        power and flexibility in her athletic pursuits. <br /> <br />
        She’s used that momentum and steely resolve to charge forward in life,
        overcoming a background of limited higher educational opportunities to
        become a standout journalism student and college athlete.
       </p>
      </Col>
      <Col md="12" className="fs-img">
       <img src={image3} alt="img" />
      </Col>
      <Col md="12" className="text-justify fs-text">
       <p>
        <br /> 'Diana Ramos is the Venezuelan national indoor high jump record holder at
        5 feet, 7.25 inches. At 5-10, University of Arizona high jumper Diana Ramo can clear
        a bar only 2 inches shorter than her height, using speed, strength,
        power and flexibility in her athletic pursuits.
        She’s used that momentum and steely resolve to charge forward in life,
        overcoming a background of limited higher educational opportunities to
        become a standout journalism student and college athlete. <br /> <br />
        Aorn in Caracas, Venezuela, Ramos, 22, was awarded the UA School of
        Journalism’s B.P. Campbell Award for outstanding junior. She set the
        Venezuelan national indoor high jump mark at 5 feet, 7.25 inches and
        jumped 5-8 in an outdoor meet last year. <br />
        <Col md="12" className="fs-img3 text-center">
         <img src={image4} alt="img" />
         </Col> <br />
         Ramos is an apprentice reporter at the Arizona Daily Star and also has
        worked at the Arizona Daily Wildcat.
        <hr className="dotted_line" />
       </p>
      </Col>
      
     </Row>
    </Container>
   </div> */}
    </>
  );
}

export default OurGoals;
