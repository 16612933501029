const initialState = {
 loading: false,
 school: [],
 college: [],
 schoolLocation: [],
 collegeLocation: [],
 searchFunding: [],
};
const schoolReducer = (state = initialState, action) => {
 switch (action.type) {
  case "GET_ALL_SCHOOL":
   return {
    ...state,
    school: action.payload,
   };

  case "GET_ALL_COLLEGE":
   return {
    ...state,
    college: action.payload,
   };

  case "GET_SCHOOL_LOCATION":
   return {
    ...state,
    schoolLocation: action.payload,
   };

  case "GET_COLLEGE_LOCATION":
   return {
    ...state,
    collegeLocation: action.payload,
   };

  case "SET_REQUEST_LOADING":
   return {
    ...state,
    loading: action.payload,
   };

  case "SEARCH_FUNDING_EARNING_RESULT":
   return {
    ...state,
    searchFunding: action.payload,
   };

  default:
   return {
    ...state,
   };
 }
};
export default schoolReducer;
