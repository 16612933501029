import firebase from '../../config/firebase';
import { toast } from 'react-toastify';
import axios from 'axios';

export const requestLoader = val => async dispatch => {
	dispatch({ type: 'SET_REQUEST_LOADING', payload: val });
};

export const request =
	(creds, onSuccess = () => {}) =>
	async dispatch => {
		dispatch(requestLoader(true));
		firebase
			.firestore()
			.collection('partnership_inquiry')
			.add(creds)
			.then(async res => {
				dispatch(requestLoader(false));
				await axios
					.post(
						'https://us-central1-fans-help-players.cloudfunctions.net/app/mailPartnership',
						{
							email: creds.email,
							location: creds.location,
							institute_name: creds.institute_name,
							video2_url: creds.video2_url,
							video1_url: creds.video1_url,
						}
					)
					.then(res => {
						toast.success(res.data.message);
					})
					.catch(err => {
						toast.success(err.data.message);
					});
				toast.success('Request Send Successfully');
				onSuccess();
			})
			.catch(err => {
				toast.warning(err.message);
				dispatch(requestLoader(false));
			});
	};

export const getSchool = () => async dispatch => {
	firebase
		.firestore()
		.collection('test_schools')
		// .orderBy("name", "asc")
		.onSnapshot(query => {
			var tempSchools = [];
			query.forEach(doc => {
				tempSchools.push({ id: doc.id, ...doc.data() });
			});
			dispatch({
				type: 'GET_ALL_SCHOOL',
				payload: tempSchools,
			});
		});
};

export const getCollege = () => async dispatch => {
	firebase
		.firestore()
		.collection('test-colleges-universities')
		// .orderBy("name", "asc")
		.onSnapshot(query => {
			var tempCollege = [];
			query.forEach(doc => {
				tempCollege.push({ id: doc.id, ...doc.data() });
			});
			dispatch({
				type: 'GET_ALL_COLLEGE',
				payload: tempCollege,
			});
		});
};

export const getSchoolLocation = data => async dispatch => {
	firebase
		.firestore()
		.collection('test_schools')
		.where('institutes', 'array-contains', data)
		// .orderBy("name", "asc")
		.onSnapshot(query => {
			var tempSchools = [];
			query.forEach(doc => {
				tempSchools.push({ id: doc.id, ...doc.data() });
			});
			dispatch({
				type: 'GET_SCHOOL_LOCATION',
				payload: tempSchools,
			});
		});
};

export const getCollegeLocation = data => async dispatch => {
	firebase
		.firestore()
		.collection('test-colleges-universities')
		.where('institutes', 'array-contains', data)
		// .orderBy("name", "asc")
		.onSnapshot(query => {
			var tempCollege = [];
			query.forEach(doc => {
				tempCollege.push({ id: doc.id, ...doc.data() });
			});
			dispatch({
				type: 'GET_COLLEGE_LOCATION',
				payload: tempCollege,
			});
		});
};

export const getSearchAthlete = id => async dispatch => {
	dispatch(requestLoader(true));
	let findAthlete = await firebase
		.firestore()
		.collection('users')
		.where('athleteID', '==', id)
		.get();
	let tempArrray = [];
	findAthlete?.docs.forEach(doc => {
		tempArrray.push({
			id: doc.id,
			...doc.data(),
		});
	});
	if (tempArrray.length > 0) {
		dispatch(requestLoader(false));
		let findAthleteId = await firebase

			.firestore()
			.collection('license_purchases')
			.where('athlete_id', '==', tempArrray[0]?.id)
			.get();

		let tempAthlete = [];

		for await (let doc of findAthleteId.docs) {
			let userRef = await firebase
				.firestore()
				.collection('users')
				.doc(doc.data().athlete_id)
				.get();
			tempAthlete.push({
				user: userRef.data(),
				id: doc.id,
				...doc.data(),
			});
		}
		// let newArr;
		// for await (let doc of tempAthlete) {
		//  if (doc.user.sportLevel == "College athlete") {
		//   let instituteRef = await firebase
		//    .firestore()
		//    .collection("test-colleges-universities")
		//    .doc(doc.user.new_institute_id)
		//    .get();

		//   newArr = [{ ...doc, institute: instituteRef.data() }];
		//  }
		// }

		// for await (let doc of tempAthlete) {
		//  if (doc.user.sportLevel == "High School athlete") {
		//   let instituteRef = await firebase
		//    .firestore()
		//    .collection("test_schools")
		//    .doc(doc.user.new_institute_id)
		//    .get();

		//   newArr = [{ ...doc, institute: instituteRef.data() }];
		//  }
		// }
		dispatch({
			type: 'SEARCH_FUNDING_EARNING_RESULT',
			payload: tempAthlete,
		});
	} else {
		dispatch(requestLoader(false));
		toast.warning('Wrong Athlete Id');
	}
};
