import React from 'react';
import { Row, Col, Container } from 'reactstrap';

function OurGoals() {
	return (
		<>
			<div>
				<Container>
					<Row className='justify-content-center px-5 py-5'>
						<Col md='4' className='mt-5 ourGoals-text'>
							<h1 className='text-center bg-primary text-white py-4'>
								Our Goals
							</h1>
						</Col>
					</Row>
					<Row className='mt-5 justify-content-center'>
						<Col md='8' className='ourGoals-text'>
							<h4 className=''>
								{/* The first involves online portal for counseling
								of student athletes during college. This is to
								change the current situation where 86% of
								student athletes go bankrupt after graduation if
								they don’t go pro in sports. The second part of
								the FHP-CA formula involves helping current or
								former student athletes of a specific Alma Mater
								find online matching to companies with job
								openings. CA partners with
								<a href='Match4Future.com'>
									{' '}
									Match4Future.com
								</a>{' '}
								service. */}
								The first GOAL involves ONLINE PORTAL FOR
								COUNSELING OF STUDENT ATHLETES during college.
								This service is designed to change the current
								situation where up to 85% of Student Athletes go
								BANKRUPT after Graduation if they don’t manage
								to Go PRO in Sports.
								<br />
								The second GOAL involves ONLINE JOB SEARCH AND
								JOB MATCH for STUDENT ATHLETES. For this match,
								a JOB PROVIDER may choose to specify STUDENT
								ATHLETES of a specific ALMA MATER be first in
								the Match.
								<br />
								To accomplish these two Goals, COLLECTIVEALUMS
								partners with the FANSHELPPLAYERS family of
								websites, including
								<a href='Match4Future.com'> Match4Future.com</a>
							</h4>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default OurGoals;
