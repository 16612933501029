import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { PhoneInput } from 'react-contact-number-input';
// import 'react-phone-input/lib/style.css'
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
	Spinner,
} from 'reactstrap';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import {
	getSchool,
	getCollege,
	getSchoolLocation,
	getCollegeLocation,
	request,
} from '../store/actions/fundingInquiryAction';
import { getInputBaseUtilityClass } from '@mui/material';

const useStyles = makeStyles({
	padding: {
		'& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root': {
			borderRadius: '50px',
		},
		'& .css-1ubg2i4 .MuiOutlinedInput-root': {
			borderRadius: '50px',
		},
	},
});

function FundingInquiry() {
	const [status, setStatus] = useState(1);
	const [schoolState, setSchoolState] = useState('');
	const [collegeState, setCollegeState] = useState('');
	const [schoolLocationValue, setSchoolLocationValue] = useState('');
	const [collegeLocationValue, setCollegeLocationValue] = useState('');

	const [email, setEmail] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	// const [role, setRole] = useState('');

	// const [enrolledStudent, setEnrolledStudent] = useState();
	// const [studentAthletes, setStudentAthletes] = useState();
	const [firstVideo, setFirstVideo] = useState('');
	const [secondVideo, setSecondVideo] = useState('');

	const dispatch = useDispatch();
	const history = useHistory();

	const { loading, school, college, schoolLocation, collegeLocation } =
		useSelector(state => state.schoolData);

	const classes = useStyles();

	useEffect(() => {
		if (school.length == 0) {
			dispatch(getSchool());
		}
		if (college.length == 0) {
			dispatch(getCollege());
		}
	}, []);
	let newSchools = [];
	const getSchoolInstitutes = () => {
		school.forEach(insti => {
			insti.institutes?.forEach(ele => {
				newSchools.push(ele);
			});
		});
		if (newSchools) {
			return newSchools;
		}
	};
	//  console.log(school, "  <-school");
	//  console.log(college, "  <-college");
	const defaultSchoolProps = {
		options: getSchoolInstitutes(),
		getOptionLabel: option => option,
	};

	let newCollege = [];
	const getCollegeInstitutes = () => {
		college.forEach(insti => {
			insti.institutes?.forEach(ele => {
				newCollege.push(ele);
			});
		});
		if (newCollege) {
			return newCollege;
		}
	};
	const defaultCollegeProps = {
		options: getCollegeInstitutes(),
		getOptionLabel: option => option,
	};

	const radioHandler = status => {
		setStatus(status);
		// setStudentAthletes('');
		// setEnrolledStudent('');
		setFirstVideo('');
		setSecondVideo('');
		// setRole('');
		setEmail('');
		setPhoneNumber(null);
		if (status == 1) {
			setSchoolState('');
			setSchoolLocationValue('');
		} else {
			setCollegeState('');
			setCollegeLocationValue('');
		}
	};

	const getSchoolId = () => {
		let schoolId =
			school &&
			school?.find(ele => ele.institutes?.find(el => el == schoolState));
		if (schoolId) {
			return schoolId.id;
		} else {
			return null;
		}
	};

	const getCollegeId = () => {
		let collegeId =
			college &&
			college?.find(ele =>
				ele.institutes?.find(el => el == collegeState)
			);
		if (collegeId) {
			return collegeId.id;
		} else {
			return null;
		}
	};

	useEffect(() => {
		if (schoolState != '') {
			dispatch(getSchoolLocation(schoolState));
		}
	}, [schoolState]);
	useEffect(() => {
		if (schoolState != '' && schoolLocation != '') {
			return setSchoolLocationValue(
				schoolLocation[0]?.county + ', ' + schoolLocation[0]?.state
			);
		}
	}, [schoolLocation]);

	useEffect(() => {
		if (collegeState != '') {
			dispatch(getCollegeLocation(collegeState));
		}
	}, [collegeState]);
	useEffect(() => {
		if (collegeState != '' && collegeLocation != '') {
			return setCollegeLocationValue(
				collegeLocation[0]?.city + ', ' + collegeLocation[0]?.state
			);
		}
	}, [collegeLocation]);

	const submit = () => {
		if (status == 1) {
			let creds = {
				contact: phoneNumber,
				location: schoolLocationValue,
				institute_state_id: getSchoolId(),
				institute_name: schoolState,
				email: email,
				video1_url: firstVideo,
				video2_url: secondVideo,
				// role_at_institute: role,
				// total_athletes: studentAthletes,
				// total_enrolled: enrolledStudent,
				type: 'school',
			};
			dispatch(
				request(creds, () => {
					history.push('/');
					setStatus(0);
					setPhoneNumber('');
					setSchoolLocationValue('');
					setSchoolState('');
					setEmail('');
					// setRole('');
					setFirstVideo('');
					setSecondVideo('');
					// setStudentAthletes('');
					// setEnrolledStudent('');
				})
			);
		} else {
			let creds = {
				contact: phoneNumber,
				location: collegeLocationValue,
				institute_state_id: getCollegeId(),
				institute_name: collegeState,
				email: email,
				video1_url: firstVideo,
				video2_url: secondVideo,
				// role_at_institute: role,
				// total_athletes: studentAthletes,
				// total_enrolled: enrolledStudent,
				type: 'college',
			};
			dispatch(
				request(creds, () => {
					history.push('/');
					setStatus(0);
					setPhoneNumber('');
					setCollegeLocationValue('');
					setCollegeState('');
					setEmail('');
					// setRole('');
					setFirstVideo('');
					setSecondVideo('');
					// setStudentAthletes('');
					// setEnrolledStudent('');
				})
			);
		}
	};
	const textSubmit = e => {
		e.preventDefault();
		submit();
	};

	const handlePhone = val => {
		let contactNumber = val.countryCode + ' ' + val.phoneNumber;
		setPhoneNumber(contactNumber);
	};
	return (
		<div className='register'>
			<Card className='register__card round shadow mt-3'>
				<CardHeader tag='h3' className='py-4'>
					Partnership Inquiry
				</CardHeader>
				<CardBody>
					<Form onSubmit={textSubmit}>
						<FormGroup>
							<div className='m-2 text-center'>
								<input
									type='radio'
									name='release'
									checked={status == 1}
									className=''
									onClick={e => radioHandler(1)}
								/>
								<Label for='exampleSchool' className='mr-3'>
									School
								</Label>
								<input
									type='radio'
									name='release'
									checked={status == 2}
									className=''
									onClick={e => radioHandler(2)}
								/>
								<Label for='exampleSchool' className=''>
									College
								</Label>
							</div>
							<div>
								{status == 1 && (
									<Autocomplete
										{...defaultSchoolProps}
										id='auto-complete1'
										autoComplete
										includeInputInList
										disableClearable={true}
										disableListWrap
										// freeSolo
										required
										onChange={(e, val) =>
											setSchoolState(val)
										}
										// loading={load.loading}
										renderInput={params => (
											<TextField
												placeholder='Select Your School'
												className={classes.padding}
												{...params}
												variant='outlined'
												sx={{ borderRadius: '50px' }}
											/>
										)}
										sx={{ borderRadius: '50px' }}
									/>
								)}
								{status == 2 && (
									<Autocomplete
										{...defaultCollegeProps}
										id='auto-complete1'
										autoComplete
										includeInputInList
										disableClearable={true}
										disableListWrap
										required
										// freeSolo
										onChange={(e, val) =>
											setCollegeState(val)
										}
										// loading={load.loading}
										renderInput={params => (
											<TextField
												placeholder='Select Your College University'
												className={classes.padding}
												{...params}
												variant='outlined'
											/>
										)}
									/>
								)}
							</div>
						</FormGroup>
						<FormGroup>
							<Label for='exampleLocation' className='mt-2'>
								Location
							</Label>
							{status == 1 ? (
								<Input
									id='exampleLocation'
									name='location'
									bsSize='lg'
									placeholder='Location'
									type='text'
									className='rounded'
									value={schoolLocationValue}
								/>
							) : (
								<Input
									id='exampleLocation'
									name='location'
									bsSize='lg'
									placeholder='Location'
									type='text'
									className='rounded'
									value={collegeLocationValue}
								/>
							)}
						</FormGroup>
						<FormGroup>
							<Label for='schoolName'>Email</Label>
							<Input
								id='schoolName'
								name='name'
								bsSize='lg'
								placeholder='Enter your email'
								required
								type='email'
								className='rounded'
								value={email}
								onChange={e => setEmail(e.target.value)}
							/>
							{/* <Label for='schoolName' className='mt-3'>
								Role At Institute
							</Label>
							<Input
								bsSize='lg'
								placeholder='Enter your aole at institute'
								required
								type='text'
								className='rounded'
								value={role}
								onChange={e => setRole(e.target.value)}
							/> */}
							<Label for='schoolName' className='mt-3'>
								Contact
							</Label>
							<PhoneInput
								bsSize='lg'
								countryCode='selected country phone code'
								placeholder='Enter your contact number'
								// value={phoneNumber}
								onChange={val => handlePhone(val)}
								// containerClass={{width: '500px',}}
								required
								// style={{ width: '500px',}}
								containerClass='my-container-class'
								inputClass='my-input-class'
								inputStyle={{
									borderRadius: '50px',
								}}
							/>
						</FormGroup>
						{/* <Row>
							<Col md={6}>
								<FormGroup>
									<Label for='exampleTES'>
										Total Enrolled Students
									</Label>
									<Input
										id='exampleTES'
										name='tes'
										type='number'
										bsSize='lg'
										className='rounded'
										required
										value={enrolledStudent}
										onChange={e =>
											setEnrolledStudent(
												parseInt(e.target.value)
											)
										}
									/>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<Label for='exampleAthletes'>
										Students Athletes
									</Label>
									<Input
										id='exampleAthletes'
										name='athletes'
										type='number'
										bsSize='lg'
										className='rounded'
										required
										value={studentAthletes}
										onChange={e =>
											setStudentAthletes(
												parseInt(e.target.value)
											)
										}
									></Input>
								</FormGroup>
							</Col>
						</Row> */}

						<Row>
							<Col md={6}>
								<FormGroup>
									<Label for='Video 1'>Video 1</Label>
									<Input
										type='text'
										bsSize='lg'
										className='rounded'
										required
										value={firstVideo}
										onChange={e =>
											setFirstVideo(e.target.value)
										}
									/>
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<Label for='Video 2'>Video 2</Label>
									<Input
										type='text'
										bsSize='lg'
										className='rounded'
										required
										value={secondVideo}
										onChange={e =>
											setSecondVideo(e.target.value)
										}
									></Input>
								</FormGroup>
							</Col>
						</Row>
						<Button
							size='lg'
							color='site-primary'
							className='d-flex justify-content-center rounded px-4 mx-auto my-4 font-weight-bold'
							type='submit'
							disabled={
								status == 1
									? !schoolLocationValue ||
									  !email ||
									  !phoneNumber ||
									  // !enrolledStudent ||
									  // !studentAthletes ||
									  !firstVideo ||
									  !secondVideo
									: !collegeLocationValue ||
									  !email ||
									  !phoneNumber ||
									  // !enrolledStudent ||
									  // !studentAthletes ||
									  !firstVideo ||
									  !secondVideo
							}
						>
							{loading ? <Spinner size='sm' /> : 'Request'}
						</Button>
					</Form>
				</CardBody>
			</Card>
		</div>
	);
}

export default FundingInquiry;
