import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Form,
	FormGroup,
	Input,
	Label,
	Spinner,
} from 'reactstrap';
import { login } from '../../store/actions/authAction';

function Login() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const dispatch = useDispatch();
	const { loading } = useSelector(state => state.authUser);
	return (
		<div className='login'>
			<Card className='login__card round shadow'>
				<CardHeader tag='h3' className='py-4'>
					Login
				</CardHeader>
				<CardBody>
					<Form
						onSubmit={e => {
							e.preventDefault();
							const data = {
								email,
								password,
							};
							dispatch(login(data));
						}}
					>
						<FormGroup>
							<Label for='exampleEmail'>Email</Label>
							<Input
								id='exampleEmail'
								name='email'
								bsSize='lg'
								placeholder='Email'
								type='email'
								className='rounded'
								required
								value={email}
								onChange={e => {
									setEmail(e.target.value);
								}}
							/>
						</FormGroup>
						<FormGroup>
							<Label for='examplePassword'>Password</Label>
							<Input
								id='examplePassword'
								name='password'
								bsSize='lg'
								placeholder='Password'
								type='password'
								className='rounded'
								required
								value={password}
								onChange={e => {
									setPassword(e.target.value);
								}}
							/>
						</FormGroup>
						<Button
							size='lg'
							color='site-primary'
							type='submit'
							className='d-flex justify-content-center rounded px-4 mx-auto my-4 font-weight-bold'
						>
							{loading ? <Spinner size='sm' /> : 'Login'}
						</Button>
						{/* <p className="text-center">
              Don't have an Account? <Link to="/register">Register</Link>
            </p> */}
					</Form>
				</CardBody>
			</Card>
		</div>
	);
}

export default Login;
