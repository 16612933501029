import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import title from '../assets/img/title.jpg';

function OurGoals() {
	return (
		<>
			<div>
				<Container>
					<Row className='px-0 px-lg-5  py-0 py-lg-5'>
						<Col md='2' className='mt-5 ml-3 fact-s-img'>
							{/*  */}

							<img src={title} alt='img' className='w-100' />
							{/* className='img-fluid' */}
						</Col>
						<Col
							md='8'
							className='text-justify py-0 py-lg-5 ml-0 ml-lg-5 factSheet-text'
						>
							{/*  */}
							<h4>
								How many student-athletes live below the poverty
								line?
								<br />
								<br />A 2019 study conducted by the National
								College Players Association found that
								<b> 86 percent</b> of college athletes live
								below the federal poverty line. Due to their
								commitment to practices, while striving to
								maintain their grades, student-athletes rarely
								have time to work a job outside of college.
								{/* <br /> ……………………………………………… <br /> */}
								<br />
								<br />
								<div style={{ border: '2px dotted' }}></div>
								<br />
								What percent of college athletes go broke?{' '}
								<br />
								<br />
								The percentage of FBS schools whose "full"
								athletic scholarships leave their players in
								poverty is 85% for those athletes who live on
								campus;
								<b> 86% for athletes who live off campus.</b>
								{/* <br /> ………………………………………………. <br /> */}
								<br />
								<br />
								<div style={{ border: '2px dotted' }}></div>
								<br />
								What percent of athletes go pro? <br />
								<br />
								<b>Fewer than 2 percent</b> of NCAA
								student-athletes go on to be professional
								athletes. In reality, most student-athletes
								depend on academics to prepare them for life
								after college. Education is important. There are
								more than 460,000 NCAA student-athletes, and
								most of them will go pro in something other than
								sports.
								<b>
									{' '}
									Helping these athletes prepare and succeed
									in life is a major goal of CollectiveAlums
									and our partner Match4Future.com.
								</b>
							</h4>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default OurGoals;

///////// Extra ////////

// import React from "react";
// import { Row, Col } from "reactstrap";
// import Limg from "../assets/img/featured.png";
// import Rimg from "../assets/img/featured2.jpg";
// function OurGoals() {
//   return (
//     <>
//       <div>
//         <Row className="mt-5">
//           <Col md="7">
//             <img className="float-right" src={Limg} alt="img" />
//           </Col>
//           <Col md="5">
//             <img className="float-left" src={Rimg} alt="img" />
//           </Col>
//         </Row>
//         <Row className="mt-5 justify-content-center">
//           <Col md="9">
//             <h5>
//               Coach Micela began working with Robles in 2008, when she was
//               attending Arizona State and began lifting weights to improve her
//               shot-put throw. Within just three months of training with Micela,
//               Robles had qualified for weightlifting nationals and decided to
//               forfeit her scholarship. She began competing across the country
//               and the world — beating every other American at the world
//               championships last year. Then, in March, Robles and fellow super
//               heavyweight competitor Holley Mangold qualified for the U.S.
//               Olympics team. (Robles beat Mangold by four kilograms.) Because of
//               her financial troubles, Micela donates much of his time and pays
//               to travel with Robles to competitions. Most Olympians make money
//               through their governing bodies, as well as sponsorships,
//               endorsements, speaking engagements, and the like. These
//               endorsements can be worth six figures or more — like Michael
//               Phelps’ $1 million deal to be a spokesman for Mazda in China — or
//               they can compensate athletes with free equipment or products.
//               PowerBar is Robles’ only product sponsorship and her name isn’t
//               yet big enough to land her any big special appearances. “It’s
//               simple,” Robles says. “If a company wants to advertise their
//               brand, there’s no benefit in sponsoring you if you’re not getting
//               any exposure.”
//             </h5>
//           </Col>
//         </Row>
//       </div>
//     </>
//   );
// }

// export default OurGoals;
