import { useSelector } from 'react-redux';
import { Card, CardBody, FormGroup, Label } from 'reactstrap';

const UserCard = () => {
	const { user } = useSelector(state => state.authUser);
	// console.log('user', user);
	return (
		// 	<Card>
		// 	<CardBody>
		// 		<FormGroup>
		// 			<Label for='name'>Name: </Label> {user?.name}
		// 		</FormGroup>
		// 		<FormGroup>
		// 			<Label for='email'>Email:</Label> {user?.email}
		// 		</FormGroup>
		// 	</CardBody>
		// </Card>

		<div className='d-flex justify-content-around align-item-ceter border p-3 Request-card'>
			<div className='pt-1'>
				<Label for='name'>Name: </Label> {user?.name}
			</div>
			<div className='pt-1'>
				<Label for='email'>Email:</Label> {user?.email}
			</div>
		</div>
	);
};

export default UserCard;
