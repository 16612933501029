const initialState = {
	EarningByInstitute: [],
	getLoading: false,
	EarningByAthlete: [],
};
const dashboardReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_LOADING':
			return {
				...state,
				getLoading: action.payload,
			};
		case 'HIGHEST_EARNING_BY_SCHOOL':
			return {
				...state,
				EarningByInstitute: action.payload,
			};
		case 'HIGHEST_EARNING_BY_ATHLETE':
			return {
				...state,
				EarningByAthlete: action.payload,
			};
		default:
			return state;
	}
};
export default dashboardReducer;
