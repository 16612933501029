import {
	Col,
	Container,
	Row,
	Card,
	CardBody,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Table,
	Button,
} from 'reactstrap';

import SportsMan from '../assets/img/CA_NewHome.png';
import MainPicture from '../assets/img/main.png';
import { Link, useHistory } from 'react-router-dom';

const NewHome = () => {
	const history = useHistory();
	return (
		<>
			<Container fluid className='pt-1 pt-md-5 bg-warning'>
				<br />
				<Row>
					<Col md={2} className='align-self-top text-right pr-0 mr-0'>
						<img
							src={MainPicture}
							className='img-fluid float-right NewHome-logo-picture'
							alt='LogoPicture '
						/>
						{/* <div className='d-none d-md-flex  NewHome-logo-enter'> */}
						{/* <Link to={`/home`}> */}
						<Button
							size='sm'
							color='primary'
							onClick={() => history.push('/home')}
							className='float-right font-weight-bold border border-dark NewHome-logo-enter'
						>
							ENTER
						</Button>
						{/* </Link> */}
						{/* </div> */}
					</Col>

					<Col
						md={7}
						className='align-self-center justify-content-left  pl-0 ml-0 NewHome-SportsMan'
					>
						{' '}
						<img
							src={SportsMan}
							className='img-fluid float-left '
							alt='SportsMan'
						/>
					</Col>
					<Col
						md={2}
						className='align-self-center justify-content-left text-center pl-0 ml-0 font-weight-bold NewHome-SportsMan'
					>
						<h3>
							{' '}
							STUDENT ATHLETE
							<br />
							<span className='text-primary font-weight-bold'>
								THANKS YOU
							</span>
						</h3>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default NewHome;
