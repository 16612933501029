import { LOGIN, LOGOUT } from '../types';
import firebase from '../../config/firebase';
export const loginLoader = val => async dispatch => {
	dispatch({ type: 'AUTH_SET_LOADING', payload: val });
};

export const login = payload => async dispatch => {
	// console.log('payload', payload);
	dispatch(loginLoader(true));
	await firebase
		.auth()
		.signInWithEmailAndPassword(payload.email, payload.password)
		.then(data => {
			firebase
				.firestore()
				.collection('collective_alum_admin')
				.doc(data.user.uid)
				.onSnapshot(doc => {
					if (doc.data()) {
						let tempUser = { id: doc.id, ...doc.data() };

						dispatch({ type: LOGIN, payload: tempUser });
						dispatch(loginLoader(false));
						// alert('User login successfully');
					} else {
						alert('Invalid user or user does not exist');
						dispatch(loginLoader(false));
					}
				});
		})
		.catch(error => {
			dispatch(loginLoader(false));

			alert(error, 'danger');
		});
};

export const logout = () => async dispatch => {
	firebase
		.auth()
		.signOut()
		.then(data => {
			dispatch({
				type: LOGOUT,
				uid: '',
				error: '',
			});
		})
		.catch(error => {
			dispatch({
				type: 'LOGOUT_FAIL',
				uid: '',
				error: error,
			});
		});
};
