import React, { useState } from 'react';
import {
	Button,
	Col,
	Input,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
} from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DemoVideo from '../assets/img/demoVideo.webm';

const CopyClipboard = () => {
	const [rows, setRows] = useState([{ email: '', title: '', link: '' }]);
	const [showDemoVideo, setShowDemoVideo] = useState(false);

	const handleChange = (e, index) => {
		let temp = [...rows];
		if (e.target.name === 'email') {
			temp[index].email = e.target.value;
		} else if (e.target.name === 'title') {
			temp[index].title = e.target.value;
		} else if (e.target.name === 'link') {
			temp[index].link = e.target.value;
		}
		setRows(temp);
	};

	const handleAdd = () => {
		let lastValue = rows[rows.length - 1];
		if (
			lastValue.email != 0 &&
			lastValue.title != 0 &&
			lastValue.link != 0
		) {
			setRows([...rows, { email: '', title: '', link: '' }]);
		} else {
			toast.warn('Enter all the missing fields...');
		}
	};

	const handleRemove = index => {
		if (rows.length - 1 !== index) {
			let temp = rows;
			temp.splice(index, 1);
			setRows([...temp]);
		} else {
			toast.warn("Can't remove the input row...");
		}
	};

	const copy = (arg, arg2) => {
		navigator.clipboard.writeText(arg);
		arg.length != 0
			? toast.success(`${arg2} copied to clipboard...`)
			: toast.warn(`No ${arg2} to be copied...`);
	};

	// OpenVideoFn
	const OpenVideoFn = () => {
		setShowDemoVideo(!showDemoVideo);
	};

	// Modal

	return (
		<>
			<div className='d-flex justify-content-between'>
				<h3 className='text-center mx-auto mt-3 mb-3'>
					Copy-ClipBoards
				</h3>
				<div
					className='float-left mt-3 mb-5 '
					onClick={() => setShowDemoVideo(true)}
				>
					<i
						class='fas fa-info-circle fa-2x pointer searchIcon'
						onClick={() => setShowDemoVideo(true)}
					></i>
				</div>
			</div>
			{rows.map((obj, index) => {
				return (
					<Row className='align-items-center m-0'>
						<Col md={10} lg={11} className='px-0'>
							<Row>
								<Col
									md={4}
									className='d-flex px-2 mt-2 mt-md-0'
								>
									<i
										onClick={() => copy(obj.email, 'Email')}
										className='copy fa fa-clone mr-1'
										aria-hidden='true'
									/>
									<Input
										// autoFocus
										name='email'
										placeholder='Enter email destination'
										className='ml-1 focusShadow'
										value={obj.email}
										onChange={e => {
											handleChange(e, index);
										}}
									/>
								</Col>
								<Col
									md={4}
									className='d-flex px-2 mt-2 mt-md-0'
								>
									<i
										onClick={() => copy(obj.title, 'Title')}
										className='copy fa fa-clone mr-1'
										aria-hidden='true'
									/>
									<Input
										name='title'
										type='select'
										value={obj.title}
										className='ml-1 focusShadow'
										onChange={e => {
											handleChange(e, index);
										}}
										required
									>
										<option value='' disabled selected>
											Select Title for email
										</option>
										<option value='This idea could really help our school'>
											This idea could really help our
											school
										</option>
										<option value='As an Alumnus - this caught my eye'>
											As an Alumnus - this caught my eye
										</option>
										<option value='Hey Coach – Our school needs this'>
											Hey Coach – Our school needs this{' '}
										</option>
										<option value='Director of Athletics – Alums support this idea'>
											Director of Athletics – Alums
											support this idea{' '}
										</option>
									</Input>
								</Col>
								<Col
									md={4}
									className='d-flex px-2 mt-2 mt-md-0'
								>
									<i
										onClick={() => copy(obj.link, 'Link')}
										className='copy fa fa-clone mr-1'
										aria-hidden='true'
									/>
									<Input
										name='link'
										type='select'
										className='ml-1 focusShadow'
										value={obj.link}
										onChange={e => {
											handleChange(e, index);
										}}
										required
									>
										<option value='' disabled selected>
											add video link to email
										</option>
										<option value='bit.ly/3wFENkP'>
											bit.ly/3wFENkP
										</option>
										<option value='bit.ly/3JnL3oS'>
											bit.ly/3JnL3oS
										</option>
										{/* <option value='https://youtu.be/wOWw3yQdigA'>
											FHP Brand
										</option>
										<option value='https://youtu.be/GNkheg2UGXc'>
											Unkle501
										</option>
										<option value='https://youtu.be/LiM6zLbTei0'>
											Job Match
										</option>
										<option value='https://youtu.be/mMMhkXC-Tx8'>
											Fair to Athletes
										</option> */}
									</Input>
								</Col>
							</Row>
						</Col>
						<Col md={2} lg={1}>
							<div className='d-flex justify-content-center'>
								{rows.length - 1 === index && (
									<Button
										className='btn_add btn-success'
										onClick={() => {
											handleAdd(index);
										}}
									>
										+
									</Button>
								)}
								<Button
									className='btn_rm btn-danger'
									onClick={() => {
										handleRemove(index);
									}}
								>
									-
								</Button>
							</div>
						</Col>
					</Row>
				);
			})}
			<Row className='justify-content-center text-align-center mt-3 mt-md-5'>
				<Col sm='8' className='mt-3 mt-md-5'>
					<div className='align-self-end'>
						<h3 className='font-weight-bold'>
							Want to help student athletes?{' '}
						</h3>
						<ul className='list-unstyled'>
							<li className='font-weight-bold'>
								1. Please send emails to your contacts at your
								Alma Mater – to the Coaches and the school
								Administration.
							</li>
							<li className='font-weight-bold'>
								2. In your emails, tell these Alma Mater
								Officials that you support the idea of letting
								Alma Mater student athletes use FHP and
								publicize FHP on campus.
							</li>
							<li className='font-weight-bold'>
								3. Also, if you want to semi-automate your email
								sending to Alma Mater officials, you can use the
								clipboard supplied above. See instruction video
								(i at right top).
							</li>
						</ul>
					</div>
				</Col>
			</Row>
			<Modal isOpen={showDemoVideo} toggle={OpenVideoFn} centered>
				<ModalBody className='bg-secondary'>
					<div className='float-right'>
						<i
							onClick={OpenVideoFn}
							className='fas fa-times searchIcon'
						></i>
					</div>
					<div className='text-center'>
						<video
							style={{ width: '400px', height: '400px' }}
							src={DemoVideo}
							controls
						></video>
					</div>
					{/* </video> */}
				</ModalBody>
			</Modal>
		</>
	);
};

export default CopyClipboard;
