import {
	Col,
	Container,
	Row,
	Card,
	CardBody,
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Table,
} from 'reactstrap';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import UserCard from '../components/UserCard';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { getFundingRequests } from '../store/actions/fundingRequestsAction';

const Dashboard = () => {
	const [currentActiveTab, setCurrentActiveTab] = useState('1');

	// Toggle active state for Tab
	const toggle = tab => {
		if (currentActiveTab !== tab) setCurrentActiveTab(tab);
	};
	const dispatch = useDispatch();

	const { fundingRequests, getLoading } = useSelector(
		state => state.fundingRequest
	);

	useEffect(() => {
		if (fundingRequests.length == 0) {
			dispatch(getFundingRequests());
		}
	}, []);

	return (
		<>
			<Container className='mt-5'>
				<UserCard />
				<br />
				<Row>
					<Col>
						<Card className='Request-card'>
							<CardBody>
								<Nav tabs>
									<NavItem>
										<NavLink
											className={classnames({
												active:
													currentActiveTab === '1',
											})}
											onClick={() => {
												toggle('1');
											}}
										>
											Funding Requests
										</NavLink>
									</NavItem>
								</Nav>
								<TabContent activeTab={currentActiveTab}>
									<TabPane tabId='1'>
										<LoadingOverlay
											active={getLoading}
											spinner
											text='Loading your content...'
										>
											<Table
												className='align-items-center table-flush mt-2'
												responsive
											>
												<thead>
													<tr>
														<th
															scope='col'
															className='custom-table-heading custom-heading'
														>
															Institute Name
														</th>
														<th
															scope='col'
															className='custom-table-heading custom-heading text-center'
														>
															Contact
														</th>

														<th
															scope='col'
															className='custom-table-heading custom-heading text-center'
														>
															Email
														</th>
														<th
															scope='col'
															className='custom-table-heading custom-heading text-center'
														>
															Location
														</th>
														<th
															scope='col'
															className='custom-table-heading custom-heading text-center'
														>
															First Video
														</th>
														<th
															scope='col'
															className='custom-table-heading custom-heading text-center'
														>
															Second Video
														</th>
													</tr>
												</thead>
												<tbody className='custom-table-body mt-3'>
													{fundingRequests?.map(
														(data, idx) => {
															return (
																<tr
																	key={idx}
																	className='text-center'
																>
																	<td
																		className='custom-table-text'
																		align='left'
																	>
																		{
																			data.institute_name
																		}
																	</td>
																	<td
																		className='custom-table-text'
																		align='center'
																	>
																		{
																			data.contact
																		}
																	</td>

																	<td
																		className='custom-table-text'
																		align='center'
																	>
																		{
																			data.email
																		}
																	</td>
																	<td
																		className='custom-table-text'
																		align='center'
																	>
																		{
																			data.location
																		}
																	</td>
																	<td
																		className='custom-table-text'
																		align='center'
																	>
																		<a
																			target='_blank'
																			href={
																				data.video1_url
																			}
																		>
																			Link1
																		</a>
																	</td>
																	<td
																		className='custom-table-text'
																		align='center'
																	>
																		<a
																			target='_blank'
																			href={
																				data.video2_url
																			}
																		>
																			Link2
																		</a>
																	</td>
																</tr>
															);
														}
													)}
												</tbody>
											</Table>
										</LoadingOverlay>
									</TabPane>
								</TabContent>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Dashboard;
