// Root Reducer

import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import schoolReducer from "./fundingInquiryReducer";
import fundingRequestReducer from "./fundingRequestsReducer";
import dashboardReducer from "./dashboardReducer";

export let rootReducer = combineReducers({
 authUser: authUserReducer,
 schoolData: schoolReducer,
 fundingRequest: fundingRequestReducer,

 heighestEarning: dashboardReducer,
});

export default rootReducer;
