import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Navbar from '../components/Navbar';

const Main = props => {
	const dispatch = useDispatch();
	let { uid } = useSelector(state => state.authUser);

	const history = useHistory();
	if (!uid) {
		history.push('/login');
	}
	return (
		<>
			<Navbar />
			{props.children}
		</>
	);
};

export default Main;
