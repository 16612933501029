import firebase from "../../config/firebase";

export const getLoader = val => async dispatch => {
 dispatch({ type: "GET_LOADING", payload: val });
};

export const getFundingRequests = () => async dispatch => {
 dispatch(getLoader(true));
 firebase
  .firestore()
  .collection("partnership_inquiry")
  .onSnapshot(async data => {
   let tagsData = [];
   for (let doc of data.docs) {
    let id = doc.id;
    let data1 = doc.data();
    tagsData.push({ id: id, ...data1 });
   }
   dispatch({
    type: "GET_FUNDING_REQUESTS",
    payload: tagsData,
   });
   dispatch(getLoader(false));
  });
};
