import React, { useState, useEffect } from 'react';
import {
	Col,
	Container,
	Row,
	Card,
	CardBody,
	Table,
	Input,
	Button,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import UserCard from '../components/UserCard';
import LoadingOverlay from 'react-loading-overlay';

const SearchAthleteFunds = () => {
	const dispatch = useDispatch();

	const { searchFunding, loading } = useSelector(state => state.schoolData);

	return (
		<Container className='mt-5'>
			<UserCard />
			<br />
			{searchFunding.length > 0 ? (
				<div>
					<Row>
						<Col>
							<Card className='mt-5 mt-md-0 Request-card'>
								<CardBody>
									<Row>
										<Col sm='12'>
											<LoadingOverlay
												active={loading}
												spinner
												text='Loading your content...'
											>
												<Table bordered responsive>
													<thead>
														<tr className='text-center'>
															<th>Name</th>
															<th>
																Institute Name
															</th>
															<th>Earnings</th>
														</tr>
													</thead>
													<tbody>
														{searchFunding &&
															searchFunding.map(
																(
																	ele,
																	index
																) => {
																	return (
																		<tr className='text-center'>
																			<td>
																				{ele
																					.user
																					.first_name +
																					' ' +
																					ele
																						.user
																						.last_name}
																			</td>
																			<td>
																				{
																					ele
																						.user
																						.institute_name
																				}
																			</td>
																			<td>
																				{ele
																					.amount
																					.value /
																					2}
																			</td>
																		</tr>
																	);
																}
															)}
													</tbody>
												</Table>
											</LoadingOverlay>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			) : (
				<Row>
					<Col>
						<Card
							className='mt-5 mt-md-0 Request-card'
							//  style={{
							//   boxShadow:
							//    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
							//  }}
						>
							<CardBody>
								{/* <Row>
        <Col sm="12"> */}
								{/* <div className='d-flex mb-3'>
										<Input
											type='text'
											placeholder='Search by athlete ID'
											className='mr-2'
										/>
										<Button color='primary'>Search</Button>
									</div> */}

								<Row>
									<Col>
										{/* <Table bordered responsive> */}
										<h3 className='text-center'>
											No Search Yet
										</h3>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}
		</Container>
	);
};

export default SearchAthleteFunds;
