import { Link } from 'react-router-dom';
// import Landing from "../components/Landing";
import NewHome from '../components/New-Home';

const AdminView = props => {
	return (
		<>
			{/* <Landing /> */}
			<NewHome />
		</>
	);
};

export default AdminView;
