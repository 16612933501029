import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { PhoneInput } from 'react-contact-number-input';
// import 'react-phone-input/lib/style.css'
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Row,
	Spinner,
	Container,
} from 'reactstrap';
import Image1 from '../../assets/img/img-1.jpg';
import Image2 from '../../assets/img/img-2.jpg';
import FundingInquiry from '../../components/FundingInquiry';
import CopyClipboard from '../CopyClipboard';

function PartnershipInquiry() {
	const [searchInput, setSearchInput] = useState(false);

	return (
		<>
			<Container>
				{searchInput == false ? (
					<Row className='px-5 py-5'>
						{/* <Col
       md="9 py-3"
       className="d-flex justify-content-center partnershipinquiry-images PartnershipInquiry-image"
      >
       <img src={Image1} alt="image-1" className="mr-4" />
       <img src={Image2} alt="image-2" />
      </Col> */}
						{/* <Col md="9" className="text-area text-justify">
       <h3 className="">
        Institutions which want to partner with CA must meet program criteria.
        The main criterion for acceptance is that your student athletes sign up
        and participate in the Fans Help Players system.
       </h3>
       <br />
       <h5 className="text-center">
        Begin the process by filling out application below:
       </h5>
      </Col> */}
						<div className='edit_instructions'>
							<h3>Hi Alumnus,</h3>
							<h6>
								<i>
									<u>
										You can help support Student Athletes at
										your Alma Mater – here’s how:
									</u>
								</i>
							</h6>
							<ol>
								<li>
									{' '}
									<h6 className='p-0 m-0'>
										<u className='p-0 m-0'>EMAIL ALERTS:</u>
									</h6>
									{''}
									You can alert your Alma Mater contacts about
									the benefits for student athletes when your
									school informs student athletes that school
									approves of student athlete participation at
									FansHelpPlayers.com (FHP).
								</li>
								<li>
									<h6 className='p-0 m-0'>
										<u className='p-0 m-0'>VIDEOS FIRST:</u>
									</h6>
									Those benefits are explained in the 2 videos
									linked on the homepage (you should watch the
									videos so you will know about the details of
									FHP program’s benefits).{' '}
								</li>
								<li>
									<h6 className='p-0 m-0'>
										<u className='p-0 m-0'>
											YOUR CONTACTS:
										</u>
									</h6>
									{''}
									It will be especially helpful if you are
									friend with any of the Athletic Directors or
									Coaches at your Alma Mater.{' '}
								</li>
								<li>
									<h6 className='p-0 m-0'>
										<u className='p-0 m-0'>PERSONAL:</u>
									</h6>
									{''}
									Please send them each a personal email
									telling them about your support of the FHP
									program. If you like, you can use the app to
									help you fill out those emails. Type/click
									to add data to the app. Then right click on
									your email compose page to bring up the app.
								</li>
							</ol>
						</div>
						<Col
							md='9'
							className='link-PartnershipInquiry fas text-center'
						>
							<a
								onClick={() => {
									setSearchInput(true);
								}}
							>
								{/* (APPLICATION FORM HERE) */}
								Proceed &#xf101;
							</a>
						</Col>
					</Row>
				) : (
					''
				)}
				{/* <div className="">{searchInput == true ? <FundingInquiry /> : ""}</div> */}

				{searchInput == true ? <CopyClipboard /> : ''}
			</Container>
		</>
	);
}

export default PartnershipInquiry;
