import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import Mimg from "../assets/img/main.png";

function Landing() {
  return (
    <Container>
      {/* <div className='landing'> */}
      <Row className="landing__main d-col d-md-flex">
        <Col xs="12" md="3" className="landing__left">
          <div className="landing__left__video1 text-center float-md-right">
            <h5>Job Match</h5>
            <a href="https://youtu.be/LiM6zLbTei0">
              https://youtu.be/LiM6zLbTei0
            </a>
          </div>

          <div className="landing__left__links text-center ">
            <Link to="/featured-story">
              <Button
                size="lg"
                color="site-primary"
                className="rounded-pill p-2 w-85"
              >
                Featured story
              </Button>
            </Link>{" "}
            <br />
            <Link to="/fact-sheet">
              <Button
                size="lg"
                color="site-primary"
                className="rounded-pill mt-2 w-85"
              >
                Fact sheet
              </Button>
            </Link>{" "}
            <br />
            <Link to="/goal">
              <Button
                size="lg"
                color="site-primary"
                className="rounded-pill mt-2 w-85"
              >
                Our Goals
              </Button>
            </Link>
          </div>
        </Col>
        <Col xs="12" md="6" className=" landing__main__img mt-3 mt-md-0">
          <img src={Mimg} alt="img" className="img-fluid" />
          <div className="landing__main__img__text d-flex w-75 float-left ml-3 mt-5 ml-5 pl-3 pl-md-3 pt-5 float-md-right">
            <h4 className="text-dark mt-4 mt-md-0">
              Watch Videos. If you agree, send emails to Alma Mater Coaches &
              Alma Mater Administrators.
            </h4>
          </div>
        </Col>
        <Col xs="12" md="3" className="landing__right text-center text-md-left">
          <div className="landing__right__video2">
            <h5>Fair to Athletes</h5>
            <a href="https://youtu.be/mMMhkXC-Tx8">
              https://youtu.be/mMMhkXC-Tx8
            </a>
          </div>
          {/* <div className='landing__right__links align-self-center d-flex'> */}
          {/* <Link to='/copy-clipboard-information'>
								<Button
									size='lg'
									color='site-primary'
									className='rounded-pill'
								>
									Your Emails Help Student Athletes
								</Button>
							</Link> */}
          <div className="landing__right__links">
            <Link to="/copy-clipboard-information">
              <Button
                size="lg"
                color="site-primary"
                className=" rounded  px-3 w-100"
              >
                Your Emails Help Student Athletes
              </Button>
            </Link>{" "}
            <div className="mt-3 text-center">
              <h5>Add Video Link</h5>
              <a
                href="https://youtu.be/Oh_eiPI_AIE
"
              >
                https://youtu.be/Oh_eiPI_AIE
              </a>
              <p>
                <a href="https://Youtu.be/zdb8fNnRsuc" target="_blank">https://Youtu.be/zdb8fNnRsuc</a>
              </p>
              <p>
                <a href="https://youtu.be/czhgYJumWOw" target="_blank">https://youtu.be/czhgYJumWOw</a>
              </p>
            </div>
          </div>
          {/* <div className='landing__right__text'>
							<h4 className='text-justify'>
								Watch Videos. If you agree, send emails to Alma
								Mater Coaches & Alma Mater Administrators.
							</h4>
						</div> */}
        </Col>
      </Row>
      {/* </div> */}
    </Container>
  );
}

export default Landing;
