import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
	Col,
	Container,
	Row,
	Card,
	CardBody,
	Table,
	Input,
	Button,
} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';
import UserCard from '../components/UserCard';
import { getFundingRequests } from '../store/actions/fundingRequestsAction';
// import { getSchool } from "../store/actions/fundingInquiryAction";

const FundingRequests = () => {
	const { fundingRequests, getLoading } = useSelector(
		state => state.fundingRequest
	);
	//  const { school } = useSelector(state => state.schoolDatas);

	//  const InstituteIdByName = id => {
	//   let temp = school.find(el => el.id == id);
	//   if (temp) {
	//    return temp.name;
	//   }
	//  };

	const dispatch = useDispatch();
	useEffect(() => {
		if (fundingRequests.length == 0) {
			dispatch(getFundingRequests());
		}
		//   dispatch(getSchool());
	}, []);

	return (
		<Container className='mt-5'>
			<UserCard />
			<br />
			<Row>
				<Col>
					<Card className='mt-5 mt-md-0'>
						<CardBody>
							<Row>
								<Col sm='12'>
									{/* <div className='d-flex mb-3'>
										<Input
											type='text'
											placeholder='Search by athlete ID'
											className='mr-2'
										/>
										<Button color='site-primary'>
											Search
										</Button>
									</div> */}
									<LoadingOverlay
										active={getLoading}
										spinner
										text='Loading your content...'
									>
										<Table bordered responsive>
											<thead>
												<tr className='text-center'>
													<th>Institute Name</th>
													<th>Contact</th>
													{/* <th>Students Enrolled</th>
													<th>Total athletes</th> */}
													<th>Email</th>
													<th>Location</th>
													<th>First Video</th>
													<th>Second Video</th>
												</tr>
											</thead>
											<tbody>
												{fundingRequests?.map(
													(data, idx) => {
														return (
															<tr
																key={idx}
																className='text-center'
															>
																{/* <td>{InstituteIdByName(data.institute_id)}</td> */}
																<td>
																	{
																		data.institute_name
																	}
																</td>
																<td>
																	{
																		data.contact
																	}
																</td>
																{/* <td>
																	{
																		data.total_enrolled
																	}
																</td>
																<td>
																	{
																		data.total_athletes
																	}
																</td> */}
																<td>
																	{data.email}
																</td>
																<td>
																	{
																		data.location
																	}
																</td>
																<td>
																	<a
																		target='_blank'
																		href={
																			data.video1_url
																		}
																	>
																		{
																			data.video1_url
																		}
																	</a>
																</td>
																<td>
																	<a
																		target='_blank'
																		href={
																			data.video2_url
																		}
																	>
																		{
																			data.video2_url
																		}
																	</a>
																</td>

																{/* <td>
																<Button color='primary'>
																	Email
																</Button>
					</td> */}
															</tr>
														);
													}
												)}
											</tbody>
										</Table>
									</LoadingOverlay>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default FundingRequests;
