import Login from '../views/auth/Login';
// import Register from "../views/auth/Register";
import MainView from '../views/MainView';
import OurGoals from '../components/OurGoals';
import FeaturedStory from '../components/FeaturedStory';
import FactSheet from '../components/FactSheet';
import PartnershipInquiry from '../views/auth/PartnershipInquiry';
import Dashboard from '../views/Dashboard';
import SearchAthleteFunds from '../views/SearchAthleteFunds';
import FundingRequests from '../views/FundingRequests';
import NewHome from '../components/New-Home';
import Landing from '../components/Landing';

let routes = [
	{
		path: '/login',
		component: Login,
		layout: 'auth',
	},
	// {
	// 	path: '/goal',
	// 	component: OurGoals,
	// 	layout: 'main',
	// },
	// {
	// 	path: '/featured-story',
	// 	component: FeaturedStory,
	// 	layout: 'main',
	// },
	// {
	// 	path: '/fact-sheet',
	// 	component: FactSheet,
	// 	layout: 'main',
	// },
	// {
	// 	path: '/funding-inquiry',
	// 	component: FundingInquiry,
	// 	layout: 'main',
	// },
	// {
	// 	path: '/',
	// 	component: MainView,
	// 	layout: 'main',
	// },
	{
		path: '/home',
		component: Landing,
		layout: 'auth',
	},
	// {
	// 	path: '/home',
	// 	component: NewHome,
	// 	layout: 'auth',
	// },
	{
		path: '/dashboard',
		component: Dashboard,
		layout: 'main',
	},
	{
		path: '/search-athlete-funds',
		component: SearchAthleteFunds,
		layout: 'main',
	},
	{
		path: '/funding-requests',
		component: FundingRequests,
		layout: 'main',
	},
];
export default routes;
