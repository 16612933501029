const initialState = {
	loading: false,
	getLoading: false,
	fundingRequests: [],
};
const fundingRequestReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_LOADING':
			return {
				...state,
				getLoading: action.payload,
			};
		case 'GET_FUNDING_REQUESTS':
			return {
				...state,
				fundingRequests: action.payload,
			};
		default:
			return state;
	}
};
export default fundingRequestReducer;
