import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
const Auth = props => {
	let { uid } = useSelector(state => state.authUser);
	console.log('uid', uid);
	const history = useHistory();

	if (uid) {
		history.push('/dashboard');
	}

	return <>{props.children}</>;
};

export default Auth;
