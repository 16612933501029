import firebase from "firebase";
const firebaseConfig = {
 apiKey: "AIzaSyAhFvijjf-QgePs3vb9pfxD0n2DAwlLGSc",
 authDomain: "fans-help-players.firebaseapp.com",
 projectId: "fans-help-players",
 storageBucket: "fans-help-players.appspot.com",
 messagingSenderId: "669033615466",
 appId: "1:669033615466:web:7c82c65c7bd5c962fc7a13",
 measurementId: "G-0H4XT7VTGD",
};
firebase.initializeApp(firebaseConfig);
export default firebase;
