import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
// import Landing from "./components/Landing";
import Auth from "./layout/Auth";
import Main from "./layout/Main";
import routes from "./routes";
import store from "./store";
import PartnershipInquiry from "./views/auth/PartnershipInquiry";
import MainView from "./views/MainView";
import CopyClipboard from "./views/CopyClipboard";
import FactSheet from "./components/FactSheet";
import FeaturedStory from "./components/FeaturedStory";
import OurGoals from "./components/OurGoals";

function App() {
 return (
  <>
   <Provider store={store}>
    <BrowserRouter>
     <Switch>
      {routes.map(route => {
       switch (route.layout) {
        case "main":
         return (
          <Route exact path={route.path}>
           <Main>
            <route.component />
           </Main>
          </Route>
         );
        case "auth":
         return (
          <Route exact path={route.path}>
           <Auth>
            <route.component />
           </Auth>
          </Route>
         );
       }
      })}

      {/* <Redirect to="/"/> */}
     </Switch>

     <div>
      <Route path="/" exact component={MainView} />
      {/* <Route path="/partnership-inquiry" component={PartnershipInquiry} /> */}
      <Route path="/copy-clipboard-information" component={PartnershipInquiry} />
      <Route path="/fact-sheet" component={FactSheet} />
      <Route path="/featured-story" component={FeaturedStory} />
      <Route path="/goal" component={OurGoals} />
      <Route path="/copy-clipboard" exact component={CopyClipboard} />
     </div>
    </BrowserRouter>
   </Provider>
  </>
 );
}

export default App;
