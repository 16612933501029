import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { logout } from '../store/actions/authAction';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	Input,
	Button,
	Spinner,
} from 'reactstrap';
import logo from '../assets/img/logo.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchAthlete } from '../store/actions/fundingInquiryAction';

function Header() {
	const [isOpen, setIsOpen] = useState(false);
	const [searchInput, setSearchInput] = useState(false);

	const [athleteId, setAthleteId] = useState('');
	let history = useHistory();
	const dispatch = useDispatch();

	const toggle = () => setIsOpen(!isOpen);

	const search = () => {
		dispatch(getSearchAthlete(Number(athleteId)));
	};

	const { loading } = useSelector(state => state.schoolData);

	return (
		<div>
			<Navbar color='light' light expand='md'>
				<NavbarBrand tag={Link} to='/'>
					<img src={logo} alt='logo' className='main-logo' />
				</NavbarBrand>
				<NavbarToggler onClick={toggle} />
				<Collapse isOpen={isOpen} navbar>
					<Nav className='ml-auto' navbar>
						<NavItem>
							<NavLink tag={Link} to='/dashboard'>
								Dashboard
							</NavLink>
						</NavItem>
						{/* <NavItem>
       <NavLink tag={Link} to="/funding-requests">
        Funding requests
       </NavLink>
      </NavItem> */}
						<NavItem>
							<Button
								color='site-primary'
								className='mx-2 my-md-0 my-2'
								onClick={() => {
									dispatch(logout());
								}}
							>
								Logout
							</Button>
						</NavItem>

						<NavItem>
							{searchInput == false ? (
								<i
									className='fa fa-search mt-2 searchIcon'
									onClick={() => {
										setSearchInput(true);
									}}
								></i>
							) : (
								''
							)}

							{searchInput == true ? (
								<>
									<div className='d-flex'>
										<Input
											type='text'
											placeholder='Search Athlete Earning'
											onClick={() => {
												history.push(
													'./search-athlete-funds'
												);
											}}
											value={athleteId}
											onChange={e =>
												setAthleteId(e.target.value)
											}
										/>

										<Button
											color='site-primary'
											className='btn ml-2'
											onClick={search}
											disabled={loading}
										>
											{loading ? (
												<Spinner size='sm' />
											) : (
												'Search'
											)}
											{/* Search */}
										</Button>
									</div>
								</>
							) : (
								''
							)}
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
		</div>
	);
}

export default Header;
